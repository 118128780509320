import React from "react";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
// import { toast } from "react-hot-toast";
// import apiRequest from '../../../lib/ApiRequest';
import { Link } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import like from "../../../assets/images/icons/like.svg";
import comments from "../../../assets/images/icons/post.svg";
import share from "../../../assets//images/icons/share.svg";
import avatar from "../../../assets/images/icons/avatar.png";

const Post = ({ post }) => {
  // const [comment, setComment] = useState("");
  const { data: authUser, refetch: refetchAuthUser } = useQuery({
    queryKey: ["authUser"],
  });
  // const queryClient = useQueryClient();

  const postOwner = post?.user;
  // const isLiked = false
  // const isMyPost = authUser?._id === post?.user._id;

  // const { mutate: deletePost, isLoading: isDeleting } = useMutation({
  //   mutationFn: async () => {
  //     try {
  //       const res = await apiRequest.delete(`/posts/${post._id}`);
  //       const data = res.data;

  //       if (res.status !== 200) {
  //         throw new Error(data.error || "Something went wrong");
  //       }
  //       return data;
  //     } catch (error) {
  //       throw new Error(error.message);
  //     }
  //   },
  //   onSuccess: () => {
  //     toast.success("Post deleted successfully");
  //     queryClient.invalidateQueries({ queryKey: ["posts"] });
  //   },
  //   onError: (error) => {
  //     toast.error(error.message);
  //   },
  // });

  // const { mutate: likePost, isLoading: isLiking } = useMutation({
  //   mutationFn: async () => {
  //     try {
  //       const res = await fetch(`/api/posts/like/${post._id}`, {
  //         method: "POST",
  //       });
  //       const data = await res.json();
  //       if (!res.ok) {
  //         throw new Error(data.error || "Something went wrong");
  //       }
  //       return data;
  //     } catch (error) {
  //       throw new Error(error.message);
  //     }
  //   },
  //   onSuccess: (updatedLikes) => {
  //     queryClient.setQueryData(["posts"], (oldData) => {
  //       return oldData.map((p) => {
  //         if (p._id === post._id) {
  //           return { ...p, likes: updatedLikes };
  //         }
  //         return p;
  //       });
  //     });
  //   },
  //   onError: (error) => {
  //     toast.error(error.message);
  //   },
  // });

  // const { mutate: commentPost, isLoading: isCommenting } = useMutation({
  //   mutationFn: async () => {
  //     try {
  //       const res = await fetch(`/api/posts/comment/${post?._id}`, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({ text: comment }),
  //       });
  //       const data = await res.json();

  //       if (!res.ok) {
  //         throw new Error(data.error || "Something went wrong");
  //       }
  //       return data;
  //     } catch (error) {
  //       throw new Error(error.message);
  //     }
  //   },
  //   onSuccess: () => {
  //     toast.success("Comment posted successfully");
  //     setComment("");
  //     queryClient.invalidateQueries({ queryKey: ["posts"] });
  //   },
  //   onError: (error) => {
  //     toast.error(error.message);
  //   },
  // });

  const handleDeletePost = () => {
    // deletePost();
  };

  // const handlePostComment = (e) => {
  //   e.preventDefault();
  // if (comment.trim() === "") return; // Ensure comment is not empty
  // commentPost();
  // };

  // const handleLikePost = () => {
  //   // likePost();
  // };

  const displayName = post?.user?.isSupplier ? (
    <>
      <span className="font-bold text-mainColor-500">
        {postOwner?.company?.[0]?.companyName}
      </span>
    </>
  ) : (
    <>
      <span>{`${postOwner?.firstName} ${postOwner?.lastName}`}</span>
    </>
  );

  const IsMyPost = authUser?._id === postOwner?._id;

  return (
    <div className="flex flex-col p-4 my-4 gap-4 bg-white rounded-lg shadow-md">
      {/* HeaderPost */}
      <div className="flex items-center justify-between">
        <Link
          to={`/profile/${postOwner?._id}`}
          className="flex items-center gap-4"
        >
          <div className="rounded-full h-12 w-12 overflow-hidden ring-2 ring-mainColor ">
            <img
              src={postOwner?.profileImg || avatar}
              alt="User Avatar"
              className="h-16 w-16 rounded-full object-cover"
            />
          </div>
          <h2 className="font-medium">
            {displayName}
            {/* firstName */}
          </h2>
        </Link>
        <span>
          {IsMyPost && (
            <FaTrash
              className="cursor-pointer hover:text-red-500"
              onClick={handleDeletePost}
            />
          )}
        </span>
      </div>
      {/* BodyPost  */}
      <div className="flex flex-col gap-4">
        <p>{post?.text}</p>
        {post.imgUrl && (
          <div className="w-full min-h-96 contents">
            <img src={post?.img} alt={post?._id} />
          </div>
        )}
      </div>

      <div className="">
        {/* FooterPost*/}
        <div className="flex items-center justify-between text-sm my-4">
          <div className="flex gap-8">
            <div className="flex items-center gap-4 bg-slate-50 p-2 rounded-xl">
              <img src={like} alt="" className="cursor-pointer h-4 w-4" />
              <span className=" text-gray-300">|</span>
              <span className=" text-gray-500">
                {post?.likes?.length}
                <span className="hidden md:inline"> Likes</span>
              </span>
            </div>
            <div className="flex items-center gap-4 bg-slate-50 p-2 rounded-xl">
              <img src={comments} alt="" className="cursor-pointer h-4 w-4" />
              <span className=" text-gray-300">|</span>
              <span className=" text-gray-500">
                {post?.comments?.length}
                <span className="hidden md:inline"> Comments</span>
              </span>
            </div>
          </div>
          <div className="">
            <div className="flex items-center gap-4 bg-slate-50 p-2 rounded-xl">
              <img src={share} alt="" className="cursor-pointer h-4 w-4" />
              <span className=" text-gray-300">|</span>
              <span className=" text-gray-500">
                0 <span className="hidden md:inline"> Shares</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Post;
