import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import apiRequest from "../lib/ApiRequest";
import Post from "../components/feed/posts/Post";
import AddPost from "../components/feed/AddPost";

const ProfilePage = () => {
  const { profileId } = useParams(); // Assuming you use profileId from the URL

  const selectedTab = "posts";

  const { data: userPost } = useQuery({
    queryKey: ["userPost"],
    queryFn: async () => {
      try {
        const res = await apiRequest.get(`posts/user/${profileId}`);
        if (res.status !== 200) {
          throw new Error("Failed to fetch user posts.");
        }

        const data = res.data;
        if (data.error) return null;
        if (!data || typeof data !== "object") {
          throw new Error("Invalid response data format.");
        }

        return data;
      } catch (error) {
        throw error; // Let useQuery handle the error
      }
    },
  });

  return (
    <div className="flex flex-col gap-6">
      {/* <div className="flex flex-col items-center justify-center rounded-md bg-white shadow-lg">
        <div className="w-full h-64 relative">
         
        </div>
        <div className="mt-20 mb-4 text-2xl font-medium"></div>
        <div className="flex items-center justify-center gap-12 mb-4">
          <div className="flex flex-col items-center">
            <span className="font-medium">0</span>
            <span className="text-sm">Posts</span>
          </div>
          <div className="flex flex-col items-center">
            <span className="font-medium">0</span>
            <span className="text-sm">Followers</span>
          </div>
          <div className="flex flex-col items-center">
            <span className="font-medium">0</span>
            <span className="text-sm">Following</span>
          </div>
        </div>
        <div className="flex items-center justify-center gap-12 mb-4">
          <button
            className={`bg-mainColor-500 text-white px-4 py-2 rounded-md transition ${
              selectedTab === "posts" ? "bg-blue-600" : "hover:bg-blue-600"
            }`}
            onClick={() => setSelectedTab("posts")}
          >
            Príspevky
          </button>
          <button
            className={`bg-mainColor-500 text-white px-4 py-2 rounded-md transition ${
              selectedTab === "calendar" ? "bg-green-600" : "hover:bg-green-600"
            }`}
            onClick={() => setSelectedTab("calendar")}
          >
            Kalendár
          </button>
          <button
            className={`bg-mainColor-500 text-white px-4 py-2 rounded-md transition ${
              selectedTab === "ratings"
                ? "bg-yellow-600"
                : "hover:bg-yellow-600"
            }`}
            onClick={() => setSelectedTab("ratings")}
          >
            Hodnotenia
          </button>
        </div>
      </div> */}
      {selectedTab === "posts" && (
        <>
          <AddPost />
          <div>
            {userPost ? (
              userPost.map((post) => <Post key={post._id} post={post} />)
            ) : (
              <p>No posts available.</p> // Fallback message
            )}
          </div>
        </>
      )}
      {selectedTab === "calendar" && <div>Calendar Content</div>}
      {selectedTab === "ratings" && <div>Ratings Content</div>}
    </div>
  );
};

export default ProfilePage;
