import { Formik } from "formik";
import * as yup from "yup";
import Input from "../../inputs/Input";
import Button from "../../Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiRequest from "../../../lib/ApiRequest";
import toast from "react-hot-toast";
import { useState } from "react";

const ResetPassword = () => {
  const initialValues = {
    email: "",
  };
  const [msg, setMsg] = useState();
  const [errorMsg, setErrorMsg] = useState(null);
  const queryClient = useQueryClient();

  // React Query mutation to handle password reset
  const { mutate: resetPasswordMutation, isLoading } = useMutation({
    mutationFn: async ({ email }) => {
      try {
        const res = await apiRequest.post("auth/passwordReset", { email });
        if (res.status !== 200) {
          throw new Error("Failed to reset password. Please check your email.");
        }

        const data = res.data;
        if (!data || typeof data !== "object") {
          throw new Error("Invalid response data format.");
        }

        return data; // Return data for onSuccess
      } catch (error) {
        console.error("Error during password reset mutation:", error.message);
        throw error; // Let useMutation handle the error
      }
    },
    onSuccess: (data) => {
      toast.success(
        "Odkaz na obnovenie hesla bol úspešne odoslaný na tvoj email."
      );
      setMsg(data.message);
      queryClient.invalidateQueries({ queryKey: ["authUser"] });
    },
    onError: (error) => {
      const message = error.response?.data?.message || "Something went wrong!";
      setErrorMsg(message);
      toast.error(message || "An error occurred during password reset.");
    },
    retry: false,
  });

  // FORM FIELD VALIDATION SCHEMA
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Invalid email format")
      .required("Please enter your email"),
  });

  // Handle form submission
  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    resetPasswordMutation(values); // Call the mutation with form values
    setSubmitting(false);
  };

  return (
    <div>
      <h3 className="text-2xl font-semibold text-center mb-4">
        Reset your password
      </h3>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit} // Use the handleSubmit function
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col gap-4">
              <Input name="email" label="Email" />
              <Button
                type="submit"
                label={
                  isSubmitting || isLoading ? "Loading..." : "Resetovať Heslo"
                }
              />
              {errorMsg && <p className="text-sm text-red-600">{errorMsg}</p>}
              {msg && <p className="text-sm text-green-600">{msg}</p>}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ResetPassword;
