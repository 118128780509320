import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomePage from "./pages/HomePage";
import ProfilePage from "./pages/ProfilePage";
import ProfilesPage from "./pages/ProfilesPage";
import NotFoundPage from "./pages/NotFoundPage";
import Ponuky from "./pages/Ponuky";
import Dopyty from "./pages/Dopyty";
import Learnig from "./pages/Learnig";
import Layout from "./pages/Layout";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import RegisterVerification from "./pages/verify/RegisterVerification";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"


const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <NotFoundPage />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      { path: "/ponuky", element: <Ponuky /> },
      { path: "/dopyty", element: <Dopyty /> },
      { path: "/learning", element: <Learnig /> },
      {
        path: ":id/verify/:token", element: <RegisterVerification />,
      },


      {
        path: "/profile",
        element: <ProfilesPage />,
        children: [{
          path: "/profile/:profileId", element: <ProfilePage />,

        }],
      },
    ],
  },
]);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
);
