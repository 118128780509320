import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import logo from "../../assets/images/logoHlavne.png";
import apiRequest from "../../lib/ApiRequest";

const RegisterVerification = () => {
  const [validUrl, setValidUrl] = useState(false);
  const param = useParams();

  useEffect(() => {
    const verifyEmailUrl = async () => {
      try {
        const url = `users/${param.id}/verify/${param.token}`;
        const data = await apiRequest.get(url);
        console.log(data);
        setValidUrl(true);
      } catch (err) {
        console.error(err);
        setValidUrl(false);
      }
    };
    verifyEmailUrl();
  }, [param]);

  return (
    <>
      {validUrl ? (
        <div className="flex flex-col items-center gap-4 mb-8">
          <img src={logo} alt="bazaar" width={80} height={80} />
          <h5 className="text-lg font-bold">Vitaj na TAPROFI</h5>
          <span className="text-gray-600">Tvoj Email bol overený</span>

          <div className="mt-6 flex justify-center items-center gap-2">
            <span>Môžeš sa prihlásiť?</span>
            <a
              href="/auth/login"
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition"
            >
              Prihlásiť sa
            </a>
          </div>
        </div>
      ) : (
        <div className="mt-12 flex justify-center items-center">
          <h1 className="text-3xl font-bold">
            404 Stránku sa nepodarilo nájsť
          </h1>
        </div>
      )}
    </>
  );
};

export default RegisterVerification;
