import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useRef, useState } from "react";
import avatar from "../../assets/images/icons/avatar.png";
import { MdOutlineAddAPhoto } from "react-icons/md";
import apiRequest from "../../lib/ApiRequest";
import toast from "react-hot-toast";
import ImageSlider from "../carousel/ImageSlider";

const ProfileHeader = () => {
  const { data: user } = useQuery({ queryKey: ["user"] }); // Refetch after the image update
  const { data: authUser } = useQuery({
    queryKey: ["authUser"],
  }); // Refetch after the image update
  const queryClient = useQueryClient();
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);

  const { mutate: profileImageMutation } = useMutation({
    mutationFn: async ({ file }) => {
      try {
        const formData = new FormData();
        formData.append("profileImg", file);

        const res = await apiRequest.post("/users/updateProfileImg", formData, {
          headers: {
            "Content-Type": "multipart/form-data", // Ensure correct headers for file upload
          },
        });

        return res.data; // Assuming the response has the data you need
      } catch (error) {
        console.error(
          "Error during UploadProfileImage mutation:",
          error.message
        );
        throw error; // Let useMutation handle the error
      }
    },
    onSuccess: (data) => {
      toast.success(data.message);
      queryClient.invalidateQueries({ queryKey: ["posts"] });
      queryClient.invalidateQueries({ queryKey: ["userPost"] });
      queryClient.invalidateQueries({ queryKey: ["authUser"] });
      queryClient.invalidateQueries({ queryKey: ["user"] });
    },
    onError: (error) => {
      const message = error.response?.data?.message || "Something went wrong!";
      toast.error(message);
    },
  });

  const handleIconClick = () => {
    fileInputRef.current.click(); // Trigger file input click
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    // Call the mutation to upload the image
    if (selectedFile) {
      profileImageMutation({ file: selectedFile }); // Wrap the file in an object
    }
  };

  const displayName = user?.isSupplier ? (
    <>
      <span className="font-bold text-mainColor-500">
        {user?.company?.[0]?.companyName}
      </span>
      <span className="pl-3 font-light text-lg text-gray-500">
        {` (${user?.firstName} ${user?.lastName})`}
      </span>
    </>
  ) : (
    <>
      <span>{`${user?.firstName} ${user?.lastName}`}</span>
    </>
  );

  const IsMyProfile = authUser?._id === user?._id;
  return (
    <div className="w-full mt-8 md:px-12 lg:px-16 xl:px-72 shadow-lg">
      <ImageSlider />

      <div className="flex">
        {/* Profile Image */}
        <div className="relative mx-3 top-[-50px] w-[168px] h-[168px] rounded-full overflow-hidden border-4 border-mainColor-500">
          <img
            src={user?.profileImg || avatar} // Display the updated profile image
            alt="Profile"
            className="w-[168px] h-[168px] object-cover"
          />
          {IsMyProfile && (
            <form>
              <div
                className="absolute bottom-4 right-4 flex items-center justify-center border-2 border-mainColor-500 bg-gray-200 p-2 rounded-full shadow-lg cursor-pointer"
                onClick={handleIconClick}
              >
                {/* Hidden file input */}
                <input
                  ref={fileInputRef}
                  onChange={handleFileChange} // Handle file selection
                  type="file"
                  accept="image/*"
                  className="hidden"
                />
                <MdOutlineAddAPhoto size={16} className="text-mainColor-500" />
              </div>
            </form>
          )}
        </div>

        {/* User Information */}
        <div className="flex flex-col items-start mt-16 px-6 relative top-[-50px]">
          <h1 className="text-5xl font-bold">{displayName}</h1>
          <p className="text-gray-600">
            {user?.followers?.length || 0} sledovatelia
          </p>
          <p className="text-gray-600">
            {user?.following?.length || 0} sledované
          </p>
        </div>

        {/* Additional User Info */}
        <div className="flex flex-col">
          <div className="mt-6">
            <h2 className="text-xl text-mainColor-500 font-semibold">
              Kontakt
            </h2>
            <p className="text-gray-600">
              <strong>Zodpovedná osoba:</strong> {user?.firstName}{" "}
              {user?.lastName}
            </p>
            <p className="text-gray-600">
              <strong>Email:</strong> {user?.email}
            </p>
            <p className="text-gray-600">
              <strong>Telefón:</strong> {user?.phoneNumber}
            </p>
            <p className="text-gray-600">
              <strong>Web:</strong> Taprofi.sk
            </p>
          </div>
        </div>
        <div className="mt-6 pl-4">
          <h2 className="text-xl text-mainColor-500 font-semibold">
            Firemné informácie
          </h2>
          <p className="text-gray-600">
            <strong>ICO:</strong> {user?.company?.[0]?.ico}
          </p>
          <p className="text-gray-600">
            <strong>DIC:</strong> {user?.company?.[0]?.dic}
          </p>
          <p className="text-gray-600">
            <strong>IC DPH:</strong> {user?.company?.[0]?.icdph}
          </p>
          <p className="text-gray-600">
            <strong>Adresa:</strong> {user?.company?.[0]?.address}
          </p>
          <p className="text-gray-600">
            <strong>Mesto:</strong> {user?.company?.[0]?.city}
          </p>
          <p className="text-gray-600">
            <strong>PSC:</strong> {user?.company?.[0]?.psc}
          </p>
        </div>
      </div>

      <div className="gap-4 px-6">
        <p className="text-gray-600">
          <strong className="text-mainColor-500">Oblasť podnikania:</strong>{" "}
          {user?.company?.[0]?.businessArea}
        </p>
        <p className="text-gray-600">
          <strong className="text-mainColor-500">Oblasť pôsobenia:</strong>{" "}
          Stropkov, Svidník, Bardejov
        </p>
      </div>

      <div className="border-t mt-6">
        <div className="flex items-center justify-start gap-4 px-6 pt-4">
          <button className="bg-mainColor-500 text-white font-bold px-4 py-2 rounded-t-xl">
            Príspevky
          </button>
          <button className="text-gray-600">Kalendár</button>
          <button className="text-gray-600">Hodnotenia</button>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
