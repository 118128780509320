import React, { useState, useRef, useEffect } from "react";
import MultiStepForm, {
  FormStep,
} from "../../stepper/components/MultiStepForm";
import Input from "../../inputs/Input";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import ApiRequest from "../../../lib/ApiRequest"; // Ensure only one import
import {
  validationSchema,
  validationSchemaIsSuplierStepTwo,
  validationSchemaIsNoSuplierStepTwo,
} from "./components/validation";
import { Field } from "formik";
import RadioInput from "../../inputs/RadioInput";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-hot-toast"; // Import toast for notification

const Register = ({ switchToLogin }) => {
  const [supplier, setSupplier] = useState(true);
  const [visible, setVisible] = useState(false);
  const [msg, setMsg] = useState();
  const [errorMsg, setErrorMsg] = useState(null);
  const [initialValues] = useState({
    isSupplier: "true",
    email: "",
    phoneNumber: "",
    password: "",
    re_password: "",
    firstName: "",
    lastName: "",
    companyName: "",
    businessArea: "",
    ico: "",
    dic: "",
    icdph: "",
    address: "",
    city: "",
    psc: "",
    promoCode: "",
  });

  const supplierSectionRef = useRef(null); // Create a ref for the supplier section

  // Scroll to supplier section when step 2 is rendered
  useEffect(() => {
    if (supplierSectionRef.current) {
      supplierSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [supplier]); // Run effect when 'supplier' changes

  const handleClick = (isSupplier) => {
    setSupplier(isSupplier);
  };

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  const { mutate: registerMutation } = useMutation({
    mutationFn: async (values) => {
      try {
        const res = await ApiRequest.post("/auth/register", {
          isSupplier: values.isSupplier,
          email: values.email.toLowerCase(),
          phoneNumber: values.phoneNumber,
          firstName: values.firstName,
          lastName: values.lastName,
          password: values.password,
          region: values.region,
          companyName: values.companyName,
          businessArea: values.businessArea,
          ico: values.ico,
          dic: values.dic,
          icdph: values.icdph,
          address: values.address,
          city: values.city,
          psc: values.psc,
        });
        if (res.status !== 200) {
          throw new Error("Failed to Register.");
        }
        const data = res.data;

        if (!data || typeof data !== "object") {
          throw new Error("Invalid response data format.");
        }

        return data; // Return data for onSuccess
      } catch (error) {
        console.error(
          "Error during register mutation:",
          error?.response?.data || error.message || error
        );
        throw error;
      }
    },
    onSuccess: (data) => {
      toast.success(data.message || "Registration successful!");
      setMsg(data.message);
      switchToLogin();
      // const profileId = data.userId; // Assuming `profileId` is returned from API response
      // navigate(`/${profileId}/verify`); // Redirect to profile page
    },
    onError: (error) => {
      const message = error.response?.data?.message || "Something went wrong!";
      setErrorMsg(message);
      toast.error(message);
    },
  });

  const handleSubmit = async (values) => {
    registerMutation(values);
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <FormStep
            stepName="Prihlasovacie údaje"
            onSubmit={() => console.log("Step1 submit")}
            validationSchema={validationSchema}
          >
            <div className="flex flex-col gap-4">
              <div className="flex items-center gap-2 mt-4">
                <p className="font-semibold" name="isSupplier">
                  Chcem:
                </p>

                <Field name="isSupplier">
                  {({ field }) => (
                    <div className=" flex flex-col md:flex-row items-center gap-2 mt-4">
                      <RadioInput
                        {...field}
                        type="radio"
                        label="Ponúkať služby"
                        value="true"
                        checked={field.value === "true"}
                        onClick={() => handleClick(true)}
                      />
                      <RadioInput
                        {...field}
                        type="radio"
                        label="Hľadať odborníkov"
                        value="false"
                        checked={field.value === "false"}
                        onClick={() => handleClick(false)}
                      />
                    </div>
                  )}
                </Field>
              </div>

              <Input name="email" label="Email" />
              <Input name="phoneNumber" label="Telefonné číslo" />
              <Input
                name="password"
                label="Heslo"
                type={visible ? "text" : "password"}
                rightIcon={visible ? MdVisibility : MdVisibilityOff}
                onIconClick={toggleVisibility}
              />
              <Input
                name="re_password"
                label="Zopakuj heslo"
                type={visible ? "text" : "password"}
                rightIcon={visible ? MdVisibility : MdVisibilityOff}
                onIconClick={toggleVisibility}
              />
            </div>
          </FormStep>
        );
      case 1:
        return supplier ? (
          <FormStep
            stepName="Firemné údaje"
            onSubmit={() => console.log("Step2 submit")}
            validationSchema={validationSchemaIsSuplierStepTwo}
          >
            {/* Attach the ref to the supplier section */}
            <div
              className="flex flex-col gap-4"
              key="supplier-info"
              ref={supplierSectionRef}
            >
              <Input name="ico" label="IČO" />
              <Input name="companyName" label="Názov spoločnosti" />
              <Input
                name="businessArea"
                label="Oblasť podnikania"
                placeholder="Kľúčové slová na základe kt. ťa nájdu zákaznici"
              />
              <Input name="firstName" label="Meno" />
              <Input name="lastName" label="Priezvisko" />
              <Input name="dic" label="DIČ" />
              <Input name="icdph" label="IČdph" />
              <Input name="address" label="Adresa" />
              <Input name="city" label="Mesto" />
              <Input name="psc" label="PSČ" />
            </div>
          </FormStep>
        ) : (
          <FormStep
            stepName="Osobné údaje"
            onSubmit={() => console.log("Step2 submit")}
            validationSchema={validationSchemaIsNoSuplierStepTwo}
          >
            <div className="flex flex-col gap-4" key="personal-info">
              <Input name="firstName" label="Meno" />
              <Input name="lastName" label="Priezvisko" />
            </div>
          </FormStep>
        );
      case 2:
        return supplier ? (
          <FormStep
            stepName="Promo kód"
            onSubmit={() => console.log("Step3 submit")}
            validationSchema={validationSchema}
          >
            <div>
              {supplier && (
                <div>
                  <Input name="promoCode" label="Promo kód" />
                  <div className="">
                    <h5 className="block text-sm font-semibold text-gray-700">
                      Nemáš Zľavový Kód
                    </h5>
                  </div>
                </div>
              )}
            </div>
          </FormStep>
        ) : null;
      default:
        return "Unknown step";
    }
  };

  return (
    <MultiStepForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      error={errorMsg}
      msg={msg}
      handleClick={handleClick}
      supplier={supplier}
    >
      {getStepContent(0)}
      {getStepContent(1)}
      {getStepContent(2)}
    </MultiStepForm>
  );
};

export default Register;
