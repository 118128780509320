import React, { useCallback, useState } from "react";

import MenuItem from "./MenuItem";
import avatarImage from "../../../assets/images/icons/avatar.png";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

const UserMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { data: authUser } = useQuery({ queryKey: ["authUser"] });
  const toggleOpen = useCallback(() => {
    setIsOpen((value) => !value);
  }, []);

  const navigate = useNavigate();

  const handleProfileClick = () => {
    if (authUser?._id) {
      navigate(`/profile/${authUser._id}`); // Navigates to the dynamic profile page
    } else {
      console.error("Profile ID not available");
    }
  };

  const handleCalendarClick = () => {
    navigate("/calendar"); // Navigates to the calendar page (example route)
  };

  const handleLogOutClick = () => {};

  return (
    <div className="">
      <div className="flex flex-row items-center gap-3">
        <div
          onClick={() => {}}
          className="hidden md:block text-sm font-semibold py-3 px-4 rounded-full hover:bg-mainColor-100 transition cursor-pointer"
        >
          Pridaj svoje služby na Taprofi
        </div>

        <div
          onClick={toggleOpen}
          className="rounded-full border px-2 py-2 lg:px-4 lg:py-2 flex justify-center items-center gap-x-3 cursor-pointer hover:shadow-md transition "
        >
          {/* <MobileMenu isOpen={isOpen} setIsOpen={setIsOpen} /> */}
          <div className="block">
            <img
              className="rounded-full h-8 w-8 block"
              alt="avatarImage"
              src={authUser?.profileImg || avatarImage}
            />
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="absolute rounded-xl shadow-md w-3/4 md:w-1/4 bg-white overflow-hidden right-0 top-20 text-sm">
          <div className="flex flex-col cursor-pointer">
            <>
              <MenuItem onClick={handleProfileClick} label="Profil" />
              <MenuItem onClick={handleCalendarClick} label="Kalendar sa" />
              <MenuItem onClick={handleLogOutClick} label="Odhlasiť sa" />
            </>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserMenu;
