import React, { useState } from "react";
import Header from "../auth/components/Header";
import Register from "../auth/register/Register";
import Login from "../auth/login/Login";
import ResetPassword from "../auth/resetPassword/ResetPassword";

const Modal = ({ modalOpen, toggleModal }) => {
  const [view, setView] = useState("login"); // State to manage the current view

  // Handle login or registration success (close modal and redirect)
  const handleLoginSuccess = () => {
    toggleModal(false); // Close the modal
    window.location.href = "/"; // Redirect to the home page or any other route
  };

  // Function to switch to RegisterPageView
  const switchToRegister = () => setView("register");

  // Function to switch to LoginPageView
  const switchToLogin = () => setView("login");

  // Function to switch to ResetPasswordView
  const switchToResetPassword = () => setView("reset-password");

  if (!modalOpen) return null; // Don't render if modal is closed

  return (
    <div className="fixed inset-0 bg-mainColor-800/70 backdrop-blur-sm flex justify-center items-center z-50 ">
      <div className="w-full md:w-4/6 lg:w-3/6 xl:w-2/5 h-full my-6 mx-full overflow-auto">
        <div
          className={`transition-all duration-300 transform ${
            modalOpen
              ? "translate-y-0 opacity-100"
              : "translate-y-full opacity-0"
          } bg-white shadow-lg rounded-lg`}
        >
          {/* Modal Header */}
          <div className="p-6">
            <Header />
          </div>

          {/* Modal Content */}
          <div className="p-12">
            {view === "login" && <Login closeDialog={handleLoginSuccess} />}
            {view === "register" && (
              <Register
                closeDialog={handleLoginSuccess}
                toggleModal={toggleModal}
                switchToLogin={switchToLogin}
              />
            )}
            {view === "reset-password" && <ResetPassword />}
          </div>

          {/* Modal Footer */}
          <div className="flex flex-col gap-2 p-6">
            {view === "login" && (
              <>
                <p className="text-center text-sm">
                  Nemáš ešte účet?
                  <button
                    onClick={switchToRegister}
                    className="text-mainColor-500 hover:underline ml-1"
                  >
                    Zaregistruj sa
                  </button>
                </p>
                <p className="text-center text-sm">
                  Zabudol si heslo?
                  <button
                    onClick={switchToResetPassword}
                    className="text-mainColor-500 hover:underline ml-1"
                  >
                    Resetovať heslo
                  </button>
                </p>
              </>
            )}

            {view === "register" && (
              <p className="text-center text-sm">
                Máš už účet?
                <button
                  onClick={switchToLogin}
                  className="text-mainColor-500 hover:underline ml-1"
                >
                  Prihlás sa
                </button>
              </p>
            )}

            {view === "reset-password" && (
              <>
                <p className="text-center text-sm">
                  Spomenul si si na heslo?
                  <button
                    onClick={switchToLogin}
                    className="text-mainColor-500 hover:underline ml-1"
                  >
                    Prihlás sa
                  </button>
                </p>
                <p className="text-center text-sm">
                  Nemáš ešte účet?
                  <button
                    onClick={switchToRegister}
                    className="text-mainColor-500 hover:underline ml-1"
                  >
                    Zaregistruj sa
                  </button>
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
